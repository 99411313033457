import { subYears } from 'date-fns';
import { FORM_FIELDS, INITIAL_FEE_LIMITS, PAGE, ValidationValues } from '../../constants';
import yup, { StoredValues } from '../../helpers/validations/validations';
import {
    isAddressHouseSpecified,
    isCorrectUnitCode,
    isDateString,
    isPassportDateCorrect,
    isPassportNumber,
    isSnilsNumber,
    dateIsEarlier,
    dateIsLater,
    dateIsLaterThanField,
    differThanNumber,
    isCorrectInn,
    russianNoFirstNumber,
    lastExperienceMonthIsCorrect,
    isAddressHasCorrectStructure,
    isNameValid,
    isRegionValid,
} from '../../helpers/validations';
import { format, pick } from '../../helpers';
import {
    LAST_EXPERIENCE_MONTHS_SOURCE,
    LAST_EXPERIENCE_YEARS_SOURCE,
    PURPOSE_SOURCE,
    UNEMPLOYMENT_REASONS,
    ADDITIONAL_PHONE_OWNER_VALUES,
    EMPLOYER_TYPE_SOURCES,
    EMPLOYMENT_VALUES,
    EDUCATION_SOURCE,
    FAMILY_STATUS_SOURCE,
    UNEMPLOYMENT_REASON_SOURCE,
    NUMBER_OF_DEPENDENTS_SOURCE,
    JOB_TYPE_SOURCE,
    INCOME_CONFIRMATION_SOURCE,
    AREA_OF_EMPLOYMENT_SOURCE,
    PURPOSE_SOURCE_VALUES,
} from '../../constants/sources';
import { getStore } from '../../store/client';
import routes from '../../constants/routes.internal.json';
import { EMPLOYMENT_TYPE_DEPS } from '../../constants/field.dependencies';
import { anyOptionValue } from './ui/suggest/auto';

type ValidatorOptions = Record<string, unknown>;
const storedValues: StoredValues = {};

const validators = (
    validations: ValidationValues,
    page: keyof typeof routes,
    options: ValidatorOptions = {},
): Record<string, yup.BaseSchema> => {
    const { amountMin, amountMax } = validations;
    const isCianBannerPage = [PAGE.LANDING].includes(page);
    const purposes = PURPOSE_SOURCE.map(({ value }) => value).concat(
        isCianBannerPage ? [PURPOSE_SOURCE_VALUES.MORTGAGE] : [],
    );

    return {
        [FORM_FIELDS.AGREEMENT]: yup
            .boolean()
            .oneOf([true], 'Для подбора кредита необходимо согласиться с правилами предоставления информации'),
        [FORM_FIELDS.PURPOSE]: yup.string().required().oneOf(purposes),
        [FORM_FIELDS.AMOUNT]: yup
            .number()
            .required()
            .integer()
            .min(amountMin, `Сумма кредита от ${format(amountMin)}`)
            .max(amountMax, `Сумма кредита до ${format(amountMax)}`),
        [FORM_FIELDS.INITIAL_FEE]: yup.number().when([FORM_FIELDS.PURPOSE], {
            is: (purpose: ValuesOf<typeof PURPOSE_SOURCE_VALUES>) =>
                ([PURPOSE_SOURCE_VALUES.NEW_CAR, PURPOSE_SOURCE_VALUES.USED_CAR] as Array<string>).includes(purpose),
            then: (schema) =>
                schema
                    .required()
                    .integer()
                    .min(INITIAL_FEE_LIMITS.MIN, `Сумма от ${format(INITIAL_FEE_LIMITS.MIN)} рублей`)
                    .max(INITIAL_FEE_LIMITS.MAX, `Сумма до ${format(INITIAL_FEE_LIMITS.MAX)} рублей`),
        }),
        [FORM_FIELDS.AUTO_BRAND]: yup.string(),
        [FORM_FIELDS.AUTO_MODEL]: yup.string(),
        [FORM_FIELDS.AUTO_YEAR]: yup.string().when([FORM_FIELDS.PURPOSE, FORM_FIELDS.AUTO_MODEL], {
            is: (purpose: ValuesOf<typeof PURPOSE_SOURCE_VALUES>, autoModel: string) =>
                ([PURPOSE_SOURCE_VALUES.USED_CAR] as Array<string>).includes(purpose) &&
                autoModel &&
                autoModel !== anyOptionValue,
            then: (schema) => schema.required(),
        }),
        [FORM_FIELDS.AUTO_BRAND_ID]: yup.string(),
        [FORM_FIELDS.AUTO_MODEL_ID]: yup.string(),
        [FORM_FIELDS.MONTHLY_SALARY]: yup
            .number()
            .required()
            .integer()
            .min(20_000, 'Минимальный доход - 20 000р')
            .max(10_000_000, 'Максимальный доход - 10 000 000р'),
        [FORM_FIELDS.REGION]: yup.string().required(),
        [FORM_FIELDS.REGION_LOCATION_ROUTE]: yup
            .string()
            .required()
            .skipIfNotChanged(storedValues, yup.string().test(isRegionValid)),
        [FORM_FIELDS.FIRST_NAME]: yup.string().required('Укажите имя').test(isNameValid),
        [FORM_FIELDS.LAST_NAME]: yup.string().required('Укажите фамилию').test(isNameValid),
        [FORM_FIELDS.MIDDLE_NAME]: yup.string().nullable(true).test(isNameValid),
        [FORM_FIELDS.EMAIL]: yup.string().required().validateEmail(),
        [FORM_FIELDS.PHONE]: yup.string().required().mobilePhone(),
        [FORM_FIELDS.BIRTHDAY]: yup
            .string()
            .required()
            .test(isDateString())
            .test(dateIsEarlier(subYears(new Date(), 18), 'Не моложе 18 лет'))
            .test(dateIsLater(subYears(new Date(), 100), 'Не старше 100 лет')),
        [FORM_FIELDS.PASSPORT_NUMBER]: yup
            .string()
            .required()
            .skipIfNotChanged(storedValues, yup.string().test(isPassportNumber)),
        [FORM_FIELDS.BIRTH_PLACE]: yup.string().required().min(3).test(russianNoFirstNumber).max(200),
        [FORM_FIELDS.PASSPORT_DATE]: yup
            .string()
            .required()
            .test(isDateString())
            .test(dateIsEarlier(new Date()))
            .test(isPassportDateCorrect(FORM_FIELDS.BIRTHDAY)),
        [FORM_FIELDS.UNIT_CODE]: yup.string().required().test(isCorrectUnitCode),
        [FORM_FIELDS.ISSUED]: yup.string().required().min(5).max(200),
        [FORM_FIELDS.REGISTRATION_STREET]: yup
            .string()
            .required()
            .skipIfNotChanged(storedValues, yup.string().test(isAddressHouseSpecified)),
        [FORM_FIELDS.REGISTRATION_DATE]: yup
            .string()
            .required()
            .test(isDateString())
            .test(dateIsEarlier(new Date()))
            .test(dateIsLaterThanField(FORM_FIELDS.BIRTHDAY, 'Не ранее даты рождения')),
        [FORM_FIELDS.RESIDENCE_STREET]: yup
            .string()
            .required()
            .skipIfNotChanged(storedValues, yup.string().test(isAddressHouseSpecified)),
        [FORM_FIELDS.EMPLOYMENT_TYPE]: yup.string().required().oneOf(Object.values(EMPLOYMENT_VALUES)),
        [FORM_FIELDS.UNEMPLOYMENT_REASON]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.UNEMPLOYMENT_REASON),
            then: (schema) => schema.required().oneOf(UNEMPLOYMENT_REASON_SOURCE.map(({ value }) => value)),
        }),
        [FORM_FIELDS.ANOTHER_UNEMPLOYMENT_REASON]: yup
            .string()
            .when([FORM_FIELDS.EMPLOYMENT_TYPE, FORM_FIELDS.UNEMPLOYMENT_REASON], {
                is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>, unemploymentReason: string) =>
                    (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.UNEMPLOYMENT_REASON) &&
                    unemploymentReason === UNEMPLOYMENT_REASONS.OTHER,
                then: (schema) => schema.required().min(5),
            }),
        [FORM_FIELDS.ORGANIZATION_NAME]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.ORGANIZATION_NAME),
            then: (schema) => schema.required().min(3).max(255),
        }),
        [FORM_FIELDS.LAST_EXPERIENCE_START_MONTH]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.LAST_EXPERIENCE_START_MONTH),
            then: (schema) =>
                schema
                    .required()
                    .oneOf(LAST_EXPERIENCE_MONTHS_SOURCE.map(({ value }) => value))
                    .test(lastExperienceMonthIsCorrect),
        }),
        [FORM_FIELDS.LAST_EXPERIENCE_START_YEAR]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.LAST_EXPERIENCE_START_YEAR),
            then: (schema) =>
                schema.required().oneOf(
                    LAST_EXPERIENCE_YEARS_SOURCE.map(({ value }) => value),
                    'Некорректное значение',
                ),
        }),
        [FORM_FIELDS.LAST_EXPERIENCE]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.LAST_EXPERIENCE),
            then: (schema) => schema.required(),
        }),
        [FORM_FIELDS.WORK_PHONE]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.WORK_PHONE),
            then: (schema) =>
                schema.required().when([FORM_FIELDS.EMPLOYER_TYPE], {
                    is: (employerType: string) => employerType !== 'IP',
                    then: (schema) =>
                        schema
                            .mobilePhone()
                            .test(differThanNumber(FORM_FIELDS.PHONE, 'Совпадает с другим телефоном в анкете')),
                }),
        }),
        [FORM_FIELDS.WORK_ADDRESS]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.WORK_ADDRESS),
            then: (schema) =>
                schema.skipIfNotChanged(storedValues, yup.string().required().test(isAddressHasCorrectStructure)),
        }),
        [FORM_FIELDS.JOB_TITLE]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.JOB_TITLE),
            then: (schema) => schema.required().min(2).max(100),
        }),
        [FORM_FIELDS.AREA_OF_EMPLOYMENT]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.AREA_OF_EMPLOYMENT),
            then: (schema) => schema.required().oneOf(AREA_OF_EMPLOYMENT_SOURCE.map(({ value }) => value)),
        }),
        [FORM_FIELDS.SENIORITY]: yup.string().required(),
        [FORM_FIELDS.JOB_TYPE]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.JOB_TYPE),
            then: (schema) => schema.required().oneOf(JOB_TYPE_SOURCE.map(({ value }) => value)),
        }),
        [FORM_FIELDS.INCOME_CONFIRMATION]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.INCOME_CONFIRMATION),
            then: (schema) => schema.required().oneOf(INCOME_CONFIRMATION_SOURCE.map(({ value }) => value)),
        }),
        [FORM_FIELDS.EDUCATION]: yup
            .string()
            .required()
            .oneOf(EDUCATION_SOURCE.map(({ value }) => value)),
        [FORM_FIELDS.FAMILY_STATUS]: yup
            .string()
            .required()
            .oneOf(FAMILY_STATUS_SOURCE.map(({ value }) => value)),
        [FORM_FIELDS.ADDITIONAL_PHONE]: yup
            .string()
            .required()
            .mobilePhone()
            .test(
                differThanNumber([FORM_FIELDS.PHONE, FORM_FIELDS.WORK_PHONE], 'Совпадает с другим телефоном в анкете'),
            ),
        [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: yup.string().required(),
        [FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO]: yup.string().when([FORM_FIELDS.ADDITIONAL_PHONE_OWNER], {
            is: (phoneOwner: string) => phoneOwner !== ADDITIONAL_PHONE_OWNER_VALUES.MY_NUMBER,
            then: (schema) => schema.required().fullName(),
        }),
        [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: yup.string().oneOf(NUMBER_OF_DEPENDENTS_SOURCE.map(({ value }) => value)),
        [FORM_FIELDS.SNILS]: yup.string().test(isSnilsNumber),
        [FORM_FIELDS.ADDITIONAL_INCOME]: yup.number(),
        [FORM_FIELDS.RENT_APARTMENT]: yup.string().notRequiredNumber(),
        [FORM_FIELDS.PROPERTY_TYPE]: yup.string(),
        [FORM_FIELDS.CAR]: yup.string(),
        [FORM_FIELDS.SMS_CODE]: yup.string(),
        [FORM_FIELDS.EMPLOYER_INN]: yup.string().test(isCorrectInn),
        [FORM_FIELDS.EMPLOYER_TYPE]: yup.string().when([FORM_FIELDS.EMPLOYMENT_TYPE], {
            is: (employmentType: ValuesOf<typeof EMPLOYMENT_VALUES>) =>
                (EMPLOYMENT_TYPE_DEPS[employmentType] || []).includes(FORM_FIELDS.EMPLOYER_TYPE),
            then: (schema) => schema.required().oneOf(EMPLOYER_TYPE_SOURCES.map(({ value }) => value)),
        }),
        [FORM_FIELDS.OKVED]: yup.string().notRequired(),
    };
};

export const getValidationSchema = (pickedFields: Array<string>) => {
    const state = getStore().getState();
    const { validations } = state.formStore;
    const { page } = state.selection;

    return yup.object(pick(validators(validations, page), pickedFields));
};
